import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import LOG_IN from "../../../gql/mutation/auth/logIn.mutation";
import { LOCAL_STORAGE_KEYS, USER_TYPE } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";

import contactImg from "../../../images/contact.jpg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const USER = "userid";
  const TOKEN = "auth_token";
  const USER_NAME = "bsports_user_name";

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  //mutation
  const [logIn, { loading, error }] = useMutation(LOG_IN);

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  function validatePhone(phone) {
    // let reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/
    let reg = /^\+?\d+$/;
    let isphone = email.match(reg);

    if (isphone) {
      return true;
    } else {
      return false;
    }
  }

  async function logInFunc() {
    if (email != null && password != null) {
      // Email validation check
      const checkMail = validateEmail(email);
      const checkPhone = validatePhone(email);
      if (!checkMail && !checkPhone) {
        window.alert("error", "You typed invalid mail");
        return null;
      }
      console.log("A");

      const { data } = await logIn({
        variables: { email, password },
      });

      console.log("B");

      if (data.logIn.errorMessage) {
        window.alert(`${data.logIn.errorMessage}`);
      } else {
        localStorage.setItem(USER, data.logIn.user._id);
        localStorage.setItem(USER_NAME, data.logIn.user.name);
        localStorage.setItem("user_email", data.logIn.user.email);
        if (data.logIn.user.kidCoinToken) {
          localStorage.setItem("kidcointoken", data.logIn.user.kidCoinToken);
        }
        if (data.logIn.user.affiliateCouponCode) {
          localStorage.setItem(
            "affiliateCouponCode",
            data.logIn.user.affiliateCouponCode
          );
        }
        // Add iuser type in Local Storage
        if (data.logIn.user.userType) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_TYPE,
            data.logIn.user.userType
          );
        }
        // Set Superbase ID in Local Storage
        if (
          data.logIn.user.superbaseInfo &&
          data.logIn.user.superbaseInfo._id
        ) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
            data.logIn.user.superbaseInfo._id
          );
        }
        window.location.reload("/base");
        navigate("/base");
        // window.location.reload("/");
      }
    } else {
      window.alert("Please fill in email & password");
    }
  }
  useEffect(() => {
    if (localStorage.getItem("userid")) {
      navigate("/base");
    } else {
    }
  }, []);

  return (
    <div
      className="relative min-h-screen bg-cover bg-center bg-fixed bg-no-repeat  flex justify-center"
      style={{ backgroundImage: `url(${contactImg})` }}
    >
      <div class="absolute inset-0 bg-black/50 "></div>
      <div className="relative z-10 sm:w-8/12 md:6/12 lg:w-4/12 max-w-lg flex justify-center items-center">
        <div className="px-6 py-8 rounded-md  bg-slate-700/90">
          <h1 className="text-center text-yellow-300 font-display font-bold mb-5 text-4xl">
            SUPER BASE LOGIN
          </h1>

          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="border-2 border-yellow-300 focus:border-yellow-400 text-stone-600 font-bold font-display w-full py-1 px-5 rounded-full mb-4"
            name="email"
            placeholder="Email Or Phone Number With Country Code"
            style={{ textTransform: "lowercase" }}
          />
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="border-2 border-yellow-300 focus:border-yellow-400 text-stone-600 font-bold font-display w-full py-1 px-5 rounded-full mb-4"
            name="password"
            placeholder={password ? password : "Password"}
            style={{ textTransform: "lowercase" }}
          />

          <button
            type="submit"
            className="w-full bg-yellow-300 hover:bg-transparent flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-2 px-8 text-center text-gray-700 duration-500 ease-in-out hover:text-yellow-300 font-extrabold font-display "
            onClick={() => (!loading ? logInFunc() : null)}
          >
            {!loading ? "LOGIN" : "Wait..."}
          </button>
        </div>
      </div>
    </div>
  );
}
