import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/home/Home";
import Header from "./components/shared/header/Header";
import Registration from "./components/pages/auth/Registration";
import Login from "./components/pages/auth/Login";
import Bases from "./components/pages/bases/Bases";
import { ToastContainer } from "react-toastify";
import Events from "./components/pages/events/Events";
import "./Custom.css";
// CSS
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      {/* <Header></Header> */}
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/base" element={<Bases />} />
        <Route path="/event" element={<Events />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
