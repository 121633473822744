import { gql } from "@apollo/client";

export default gql`
  query findSuperbaseDetails($superbaseSlug: String, $superbaseId: String) {
    findSuperbaseDetails(
      superbaseSlug: $superbaseSlug
      superbaseId: $superbaseId
    ) {
      _id
      name
      slug
      logo
      bannerImage
      lattitude
      longitude
      city
      country
      organizerUserId
      playersType
      members
      difficulty
      sport
      createdBy
      type
      description
      schedules {
        _id
        scheduleType
        caption
        dateTime
        duration
        sports
        gameType
        slot
      }
    }
  }
`;
