import React from "react";

import { NavHashLink, HashLink } from "react-router-hash-link";

function SideMenu(props) {
  const hideMenu = props.hideMenu;

  return (
    <div className="lg:hidden h-screen opacity-95 overflow-hidden">
      <div className="collapse-content text-3xl ml-5 font-semibold px-8 py-8  text-yellow-300 ease-in-out duration-700">
        <ul>
          <li className="mt-5">
            <HashLink
              className="border-b-2 border-yellow-300 hover:text-4xl duration-500"
              smooth
              onClick={hideMenu}
              to="/home#about"
            >
              About
            </HashLink>
          </li>
          <li className="mt-5">
            <HashLink
              className="border-b-2 border-yellow-300 hover:text-4xl duration-500"
              smooth
              onClick={hideMenu}
              to="/home#membership"
            >
              Membership
            </HashLink>
          </li>

          <li className="mt-5">
            <HashLink
              className="border-b-2 border-yellow-300 hover:text-4xl duration-500"
              smooth
              onClick={hideMenu}
              to="/home#activities"
            >
              Activities
            </HashLink>
          </li>

          <li className="mt-5">
            <HashLink
              className="border-b-2 border-yellow-300 hover:text-4xl duration-500"
              smooth
              onClick={hideMenu}
              to="/home#schedule"
            >
              Schedule
            </HashLink>
          </li>

          <li className="mt-5">
            <HashLink
              className="border-b-2 border-yellow-300 hover:text-4xl duration-500"
              smooth
              onClick={hideMenu}
              to="/home#location"
            >
              Location
            </HashLink>
          </li>
          <li className="mt-5">
            <HashLink
              className="border-b-2 border-yellow-300 hover:text-4xl duration-500"
              smooth
              onClick={hideMenu}
              to="/home#join"
            >
              Join
            </HashLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideMenu;
