import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
// import SEND_MESSAGE from "../../../gql/mutation/contact/sendMessage.mutaion";
import contactImg from "../../../images/contact.jpg";
import { useQuery, useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";

// Mutations
import SAVE_BSPORTS_SUPERBASE_CONTACT_INFO from "../../../gql/mutation/auth/saveBSportSuperbaseContactInfo.mutation";
import { CONTACT_FORM_TYPES } from "../../../utils/constant";

const Registration = ({ match }) => {
  // Mutations
  const [
    saveBSportSuperbaseContactInfo,
    { error: saveBSportSuperbaseContactInfoError },
  ] = useMutation(SAVE_BSPORTS_SUPERBASE_CONTACT_INFO);

  const [name, setName] = useState(null);
  const [childName, setChildName] = useState(null);
  const [email, setEmail] = useState(null);
  const [kidGender, setKidGender] = useState("");
  const [game, setGame] = useState([]);
  const [gurdian, setGurdian] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [submissionState, setSubmissionState] = useState(false);

  const sports = [
    "Tennis",
    "Pickleball",
    "Basketball",
    "Hockey",
    "Volleyball",
    "Baseball",
    "Cricket",
    "Soccer",
    "Football",
  ];

  const relation = [
    "Father",
    "Mother",
    "Uncle",
    "Grandparent",
    "Coach",
    "Teacher",
    "Aunt",
    "Sibling",
    "Friend",
    "Cousin",
  ];

  const handleSports = (sportName) => {
    if (game.includes(sportName)) {
      setGame(game.filter((sport) => sport !== sportName));
    } else {
      setGame([...game, sportName]);
    }
  };

  async function submitContactInfo() {
    if (name == "" || !name) {
      toast.error("Please enter your full name!");
      return;
    } else if (email == "" || !email) {
      toast.error("Please enter your email or phone number!");
      return;
    } else if (childName == "" || !childName) {
      toast.error("Please enter your child's name!");
      return;
    } else if (kidGender == "" || !kidGender) {
      toast.error("Please select child's gender!");
      return;
    } else if (!game || game.length == 0) {
      toast.error(
        "Please choose the sports that your child is most interested in!"
      );
      return;
    } else if (gurdian == "" || !gurdian) {
      toast.error("Please select your relationship with the child!");
      return;
    }

    setLoadingState(true);
    console.log(name, email, childName, kidGender, game, gurdian);
    const { data } = await saveBSportSuperbaseContactInfo({
      variables: {
        name,
        email,
        contactType: CONTACT_FORM_TYPES.CONTACT_TO_BSPORT_SUPERBASE_OWNER,
        childName,
        kidGender,
        sports: game,
        relation: gurdian,
      },
    });

    if (data.saveBSportSuperbaseContactInfo.errorMessage == null) {
      toast.success("Successfully registered!");
      setLoadingState(false);
    } else {
      toast.error(data.saveBSportSuperbaseContactInfo.errorMessage);
      setLoadingState(false);
    }

    setSubmissionState(true);
  }

  return (
    <div
      className="w-full relative min-h-screen bg-cover bg-center bg-fixed bg-no-repeat  flex justify-center"
      style={{ backgroundImage: `url(${contactImg})` }}
    >
      <div class="absolute inset-0 bg-black/50 "></div>

      {submissionState ? (
        <div className="flex flex-col items-center justify-center">
          <div
            style={{ backgroundColor: "black", opacity: 0.6 }}
            className="rounded-lg shadow-lg p-8 flex flex-col items-center justify-center mx-10"
          >
            <FaCheckCircle className="text-green-500 text-8xl" />
            <div className="text-white text-2xl mt-8 text-center">
              Thank you for registering to be part of the very first Basement
              Sports Super Base! You will receive an email and follow-up with
              instructions for how to take the next steps in securing your
              Membership. In the meantime, mark your calendar for the big grand
              opening on{" "}
              <b style={{ color: "yellow" }}>
                September 13, 2023 @ 3pm ET in Jersey City
              </b>
              !
            </div>
          </div>
        </div>
      ) : (
        <div className="relative z-10 sm:w-8/12 md:6/12 lg:w-4/12 max-w-lg flex justify-center items-center">
          <div className="px-6 py-5 rounded  bg-slate-700/90">
            <h1 className="text-center text-yellow-300 font-display font-bold mb-4 text-4xl">
              SUPER BASE REGISTRATION
            </h1>
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="border-2 border-yellow-300 focus:border-yellow-400 text-stone-600 font-bold font-display w-full py-1 px-5 rounded-full mb-4"
              name="firstname"
              placeholder="Your Full Name"
            />

            {/* EMAIL */}
            {/* EMAIL */}
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="border-2 border-yellow-300 focus:border-yellow-400 text-stone-600 font-bold font-display w-full py-1 px-5 rounded-full mb-4"
              name="email"
              placeholder="Email Or Phone Number With Country Code"
              style={{ textTransform: "lowercase" }}
            />

            {/* CHILD NAME */}
            {/* CHILD NAME */}
            <input
              onChange={(e) => setChildName(e.target.value)}
              type="text"
              className="border-2 border-yellow-300 focus:border-yellow-400 text-stone-600 font-bold font-display w-full py-1 px-5 rounded-full mb-4"
              name="childname"
              placeholder="Your Child's Name"
            />

            {/* GENDER */}
            {/* GENDER */}
            <div class="flex items-center ml-4">
              <div class="flex items-center mr-4">
                <input
                  id="inline-radio"
                  type="radio"
                  value={kidGender}
                  onClick={() => setKidGender("male")}
                  name="inline-radio-group"
                  class="w-4 h-4 text-yellow-300 bg-gray-100 border-gray-300 focus:ring-yellow-300  focus:ring-2"
                />
                <label
                  for="inline-radio"
                  class="ml-2 text-sm font-medium text-yellow-300"
                >
                  Boy
                </label>
              </div>
              <div class="flex items-center ">
                <input
                  id="inline-2-radio"
                  onClick={() => setKidGender("female")}
                  type="radio"
                  value={kidGender}
                  name="inline-radio-group"
                  class="w-4 h-4 text-yellow-300 bg-gray-100 border-gray-300 focus:ring-yellow-300  focus:ring-2"
                />
                <label
                  for="inline-2-radio"
                  class="ml-2 text-sm font-medium text-yellow-300"
                >
                  Girl
                </label>
              </div>
            </div>

            {/* SPORTS */}
            {/* SPORTS */}
            <div className="my-3">
              <p className="ml-2 mb-1 text-md font-medium text-yellow-300 ">
                Which Sports is your child most interested in?
              </p>
              <div className="flex flex-wrap justify-center">
                {sports.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => handleSports(item)}
                    className={`${
                      game.includes(item) ? "bg-black/60" : "bg-black/30"
                    }  text-yellow-300   cursor-pointer m-1 px-3 py-1 rounded-full`}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>

            {/* RELATION */}
            {/* RELATION */}
            <div className="my-3">
              <p className="ml-2 mb-1 text-md font-medium text-yellow-300 ">
                Your Relationship to Child:
              </p>
              <div className="flex flex-wrap justify-center">
                {relation.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => setGurdian(item)}
                    className={`${
                      gurdian == item ? "bg-black/60" : "bg-black/30"
                    } text-yellow-300  cursor-pointer m-1 px-3 py-1 rounded-full`}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>

            {loadingState ? (
              <button
                type="submit"
                className="w-full bg-yellow-300 hover:bg-transparent flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-2 px-8 text-center text-gray-700 duration-500 ease-in-out hover:text-yellow-300 font-extrabold font-display "
              >
                Loading
              </button>
            ) : (
              <button
                type="submit"
                onClick={() => submitContactInfo()}
                className="w-full bg-yellow-300 hover:bg-transparent flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-2 px-8 text-center text-gray-700 duration-500 ease-in-out hover:text-yellow-300 font-extrabold font-display "
              >
                JOIN
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
