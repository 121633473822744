import React from "react";
import activity from "../../../images/activities.jpg";

function Activities() {
  return (
    <section
      id="activities"
      style={{ backgroundImage: `url(${activity})` }}
      className="relative min-h-96 bg-cover bg-center  bg-no-repeat  flex justify-center py-10"
    >
      <div class="absolute inset-0 bg-black/60 "></div>
      <div className="z-10 max-w-4xl mx-auto ">
        <h1 className="text-5xl font-semibold text-yellow-300 text-center my-10">
          FEATURED ACTIVITIES
        </h1>
        <h3 className="text-4xl font-semibold text-yellow-300 text-center mb-8">
          SMALL-SIDE GAMES
        </h3>
        <p className="text-3xl font-semibold text-center text-white mb-10">
          1v1, 2v2, 3v3 mini-games, with quick turnover, keeps everyone engaged.{" "}
        </p>
        <h3 className="text-4xl font-semibold text-yellow-300 text-center mb-8">
          SKILLS TEST
        </h3>
        <p className="text-3xl font-semibold text-center text-white mb-10">
          Consistent skill building and fun including 3 point contests, speed
          dribbling and dunk competitions.
        </p>
        <h3 className="text-4xl font-semibold text-yellow-300 text-center">
          ALT SKILL BUILDING
        </h3>
        <p className="text-3xl font-semibold text-center text-white mt-8 mb-10">
          For comentating to refereeing games to stats and scorekeeping, kids at
          Super Bases are constantly doing and learning new things!
        </p>
      </div>
    </section>
  );
}

export default Activities;
