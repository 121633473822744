const USER_TYPE = {
  SUPER_BASE_OWNER: "superbaseOwner",
};

const LOCAL_STORAGE_KEYS = {
  USER_ID: "userid",
  USER_TYPE: "userType",
  SUPER_BASE_ID: "superbaseId",
};

const CONTACT_FORM_TYPES = {
  BSPORTS_CONTACT: "bSportsContact",
  CONTACT_TO_SUPERBASE_OWNER: "contactToSuperbaseOwner",
  CONTACT_TO_BSPORT_SUPERBASE_OWNER: "contactToBSportSuperbaseOwner",
};

module.exports = {
  USER_TYPE,
  LOCAL_STORAGE_KEYS,
  CONTACT_FORM_TYPES,
};
