import gql from "graphql-tag";

export default gql`
  mutation saveBSportSuperbaseContactInfo(
    $name: String
    $email: String!
    $contactType: String!
    $childName: String!
    $kidGender: String!
    $sports: [String]!
    $relation: String
  ) {
    saveBSportSuperbaseContactInfo(
      name: $name
      email: $email
      contactType: $contactType
      childName: $childName
      kidGender: $kidGender
      sports: $sports
      relation: $relation
    ) {
      token
      user {
        _id
      }
      errorMessage
    }
  }
`;
